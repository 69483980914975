const SET_CORP = "SET_CORP_NO";
const SET_TASK = "SET_TASK_NO";
const INCREASE_STEP = "INCREASE_STEP";
const DECREASE_STEP = "DECREASE_STEP";

const initialState = {
  step: 0,
  corpNo: "",
  corpNm: "",
  corpNm2: "",
  taskNo: "",
  cntrYn: "",
  corpBnrImg: "",
  hashValue: "",
  qualificationYn: "",
  crerYn: "",
};

const main = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case SET_CORP:
      return {
        step: 0,
        corpNo: rest.payload.corpNo,
        corpNm: rest.payload.corpNm,
        corpNm2: rest.payload.corpNm2,
        taskNo: "",
        cntrYn: "",
        corpBnrImg: rest.payload.corpBnrImg,
        hashValue: rest.payload.hashValue,
      };
    case SET_TASK:
      return {
        ...state,
        step: 1,
        taskNo: rest.payload.taskNo,
        cntrYn: rest.payload.cntrYn,
        qualificationYn: rest.payload.qualificationYn,
        crerYn: rest.payload.crerYn,
        lifeQualificationYn: rest.payload.lifeQualificationYn,
        fireQualificationYn: rest.payload.fireQualificationYn,
        lifeCrerYn: rest.payload.lifeCrerYn,
        fireCrerYn: rest.payload.fireCrerYn,
        exclYn: rest.payload.exclYn,
      };
    case INCREASE_STEP:
      return {
        ...state,
        step: state.step + 1,
      };
    case DECREASE_STEP:
      return {
        ...state,
        step: state.step - 1,
      };
    default:
      return state;
  }
};

export const setCorp = ({ corpNo, corpNm, corpNm2, corpBnrImg, hashValue}) => ({
  type: SET_CORP,
  payload: { corpNo, corpNm, corpNm2 ,corpBnrImg, hashValue},
});
export const setTask = (taskNo, cntrYn, qualificationYn, crerYn, lifeQualificationYn, fireQualificationYn, lifeCrerYn, fireCrerYn, exclYn) => ({
  type: SET_TASK,
  payload: { taskNo, cntrYn, qualificationYn, crerYn, lifeQualificationYn, fireQualificationYn, lifeCrerYn, fireCrerYn, exclYn },
});
export const increaseStep = () => ({ type: INCREASE_STEP });
export const decreaseStep = () => ({ type: DECREASE_STEP });

export default main;
